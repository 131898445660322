import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import {NgxPaginationModule} from 'ngx-pagination';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material';
import { MatIconModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatSelectModule } from '@angular/material';

import { CKEditorModule } from 'ng2-ckeditor';

import { JsonFileService } from './services/datafile/jsondata.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AboutusComponent } from './components/home/aboutus.component';
import { AboutuscnComponent } from './components/home/aboutus-cn.component';
import { ContactusComponent } from './components/home/contactus.component';

import { ProdlistComponent } from './product/components/prodlist.component';

import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';

import { ContactusService } from './services/customer/contactus.service';

import { NewsComponent } from './components/news/news.component';
import { NewsEditComponent } from './components/news/news-edit.component';
import { NewsDetailComponent } from './components/news/news-detail.component';
import { RssService } from './services/news/rss.service';
import { NewsService } from './services/news/news.service';
import { NewscatService } from './services/news/newscat.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutusComponent,
    AboutuscnComponent,
    ContactusComponent,
    ProdlistComponent,

    NewsComponent, NewsEditComponent, NewsDetailComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    FlexLayoutModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    NgxPaginationModule,

    CKEditorModule,

    AppRoutingModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    JsonFileService,
    ContactusService,
    NewsService, NewscatService,
    RssService,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
