import {Injectable} from '@angular/core';
import {Http, Request, Response, Headers, RequestMethod, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs/Rx';

import {Comment} from '../../blog/models/comment';
import {NewsCategory} from '../../models/news/news-category';

@Injectable()
export class NewscatService {
    constructor (private http: Http) {}

    // For create
    public create(newscat: NewsCategory) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Post,
            url: sessionStorage.getItem('_ApiUrl') + '/newscat/create',
            headers: headers,
            body: JSON.stringify(newscat)
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                return res;
            })
            .catch(this.logAndPassOn);
    }

    // Get newscat
    public get(newscatId: number) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        // headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Get,
            url: sessionStorage.getItem('_ApiUrl') + '/newscats/' + newscatId,
            headers: headers
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                if (res) {
                    return this.convertResponseToNewscat(res);
                }
        })
        .catch(this.logAndPassOn);
    }

    // Get all newscats
    public getAll() {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        // headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Get,
            url: sessionStorage.getItem('_ApiUrl') + '/newscats',
            headers: headers
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                if (res) {
                    const retJson = res.json();
                    return <NewsCategory[]>retJson;
                    // return [{ status: res.status, json: res.json()}];
                } else {
                    return null;
                }
        })
        .catch(this.logAndPassOn);
    }

    // Update news
    update(newscat: NewsCategory) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Post,
            url: sessionStorage.getItem('_ApiUrl') + '/newscats/update',
            headers: headers,
            body: JSON.stringify(newscat)
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                    const retJson = res.json();
                    return <NewsCategory>retJson;
                    // return [{ status: res.status, json: res.json()}];
        })
        .catch(this.logAndPassOn);
    }

    private convertResponseToNewscat(res: Response) {
        if (res && res.status === 200) {
            const retJson = res.json();
            if (retJson) {
                const newscat = new NewsCategory(retJson['_id'], retJson['parentId'], retJson['category']);
                return newscat;
            }
        }
        return null;
    }

    private logAndPassOn (error: Error) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console

        console.error(error);
        return Observable.throw(error);
    }
}
