import {Injectable} from '@angular/core';
import {Http, Response} from '@angular/http';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class JsonFileService {
    constructor(private http: Http) {
        this.getApiUrl();
    }

    get apiUrl(): string {
        return sessionStorage .getItem('_ApiUrl');
    }

    // Read a Json file
    getJsonFile(filePath: string) {
        return this.http.get(filePath).map( (res: Response) => res.json() ).catch(this.logAndPassOn);
    }

    // Get ApiUrl from app-config.json and save to sessionStorage;
    getApiUrl() {
        //alert("Url-: " + sessionStorage .getItem('_ApiUrl'));
        if (!sessionStorage .getItem('_ApiUrl')) {
          this.getJsonFile('data/app-config.json').subscribe(
                ret => sessionStorage .setItem('_ApiUrl', ret.endpoint),
                error => Observable.throw(error)
            );
        }
    }

    private logAndPassOn (error: Error) {
        return Observable.throw(error);
    }
}
