import {Injectable} from '@angular/core';
import {Http, Request, Response, Headers, RequestMethod, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs/Rx';

import {Comment} from '../../blog/models/comment';
import {News} from '../../models/news/news';

@Injectable()
export class NewsService {
    constructor (private http: Http) {}

    // For create
    public create(news: News) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Post,
            url: sessionStorage.getItem('_ApiUrl') + '/newses/create',
            headers: headers,
            body: JSON.stringify(news)
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                return res;
            })
            .catch(this.logAndPassOn);
    }

    // Get news
    public get(newsId: string) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Get,
            url: sessionStorage.getItem('_ApiUrl') + '/newses/' + newsId,
            headers: headers,
            body: '_id=' + newsId
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                if (res) {
                    // alert(JSON.stringify(res.json()))
                    return <News>res.json();
                    //return this.convertResponseToNews(res);
                }
        })
        .catch(this.logAndPassOn);
    }

    // Get news
    public getPage(page: number, size: number) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions:RequestOptions = new RequestOptions({
            method: RequestMethod.Get,
            url: sessionStorage.getItem('_ApiUrl') + '/newses/' + page + '/' + size,
            headers: headers
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                if (res) {
                    //alert(JSON.stringify(res.json()))
                    return <News>res.json();
                    //return this.convertResponseToNews(res);
                }
        })
        .catch(this.logAndPassOn);
    }

    // Get news
    public getMany(data) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        // headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Get,
            url: sessionStorage.getItem('_ApiUrl') + '/newses',
            headers: headers,
            body: JSON.stringify(data)
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                if (res) {
                    const retJson = res.json();
                    return <News[]>retJson;
                    // return [{ status: res.status, json: res.json()}];
                } else {
                    return null;
                }
        })
        .catch(this.logAndPassOn);
    }

    // Get remote webpage content
    public fetchRemotePage(url: string) {
        const headers = new Headers();
        headers.append('x-forwarded-host', 'foo');
       return this.http.get(url, {headers: headers})
                    .subscribe(response => {
                            return response.json();
                        });
    }

    // Update news
    update(news: News) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Post,
            url: sessionStorage.getItem('_ApiUrl') + '/newses/update',
            headers: headers,
            body: JSON.stringify(news)
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                    const retJson = res.json();
                    return <News>retJson;
                    // return [{ status: res.status, json: res.json()}];
        })
        .catch(this.logAndPassOn);
    }

    private convertResponseToNews(res: Response) {
        if (res && res.status === 200) {
            const retJson = res.json();
            if (retJson) {
                const news = new News(retJson['_id'], retJson['parentId'], retJson['catId'], retJson['title'],
                                retJson['subtitle'], retJson['content'], retJson['authorId'], retJson['lanCode'],
                                retJson['keywords'], retJson['tags'], retJson['status']);
                news.active = (retJson['active'] === 1) ? true : false;

                if (retJson['comments']) {
                    news.comments = new Array<Comment>();
                    retJson['comments'].forEach(item => {
                        news.comments.push(item);
                    });
                }
                return news;
            }
        }
        return null;
    }

    private logAndPassOn (error: Error) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console

        console.error(error);
        return Observable.throw(error);
    }
}
