import { Component, OnInit } from '@angular/core';
import {Http, Request, Response, Headers, RequestMethod, RequestOptions} from '@angular/http';
import { News } from '../../models/news/news';
import { NewsService } from '../../services/news/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
  providers: [NewsService]
})
export class NewsComponent implements OnInit {
    p = 1;
    news: News;
    fetchedHtml: String;

    constructor( private _newsService: NewsService, private http: Http) {}

    ngOnInit() {
        this.load();
    }

    load() {
        // this._newsService.getMany('').subscribe(
        this._newsService.getPage(this.p - 1, 200).subscribe(
            // ret => alert(JSON.stringify(ret)),
            ret => this.news = ret,
            err => this.handleError(err)
        )
    }

    goDetail(news: News) {
        // alert(news.link);

        const headers = new Headers();
        headers.append('x-forwarded-host', 'foo');
        this.http.get(news.link, {headers: headers}).subscribe(response => {
            this.fetchedHtml = response.json();
        alert(response.json());
        });
    }

    handleError(err) {
    }
}
