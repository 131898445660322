import { Component } from '@angular/core';

import { Contactus } from '../../models/customer/contactus';
import { ContactusService } from '../../services/customer/contactus.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent {
  contactContent: string;
  contactEmail: string;
  message: string;
  errMsg: string;

  constructor(private _contactService: ContactusService){
  }

  submit() {
    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (!EMAIL_REGEXP.test(this.contactEmail)) {
        alert('Invalid email entered !');
        return;
    }

    if (this.contactContent && this.contactContent.length > 19) {
      const contactUs: Contactus = { email: this.contactEmail, content: this.contactContent };
      this.contactEmail = '';
      this.contactContent = '';
      this._contactService.createContactus(contactUs).subscribe(
        ret => {
            this.message = 'Thanks you for contacting us, we will respond as soon as possible !';
            this.errMsg = null;
        },
        err => {
            this.errMsg = 'Submission unsuccessful, please try again !';
            this.message = null;
        }
      );

    } else {
        this.errMsg = 'Content must be at least 20 characters !';
        this.message = null;
    }

  }
}
