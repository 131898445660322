import { Component, OnInit } from '@angular/core';
import { News } from '../../models/news/news';
import { NewsService } from '../../services/news/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
  providers: [NewsService]
})
export class NewsDetailComponent implements OnInit {
    p = 1;
    news: News;

    constructor( private _newsService: NewsService) { }

    ngOnInit() {
        this.load();
    }

    load() {
        // this._newsService.getMany('').subscribe(
        this._newsService.getPage(this.p - 1, 200).subscribe(
            // ret => alert(JSON.stringify(ret)),
            ret => this.news = ret,
            err => this.handleError(err)
        )
    }

    goDetail(news: News) {
        alert(news.link);
    }

    handleError(err) {
    }
}
