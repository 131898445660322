export class NewsCategory {
    _id: number;
    parentId?: number;
    cat: string;
    seq?: number;

    constructor(id: number, parentId: number, cat: string){
        this._id = id;
        this.parentId = parentId;
        this.cat = cat;
    }
}
