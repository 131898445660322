export class Prod {
    _id: number;
    title: string;
    subtitle: string;
    summary: string;
    description: string;
    thumbnail: string;
    mainImg: string;
    imgs: string[];
    seq: number;
    active = true;

    constructor(id: number, title: string, subtitle: string, thumbnail: string, images: string[]) {
        this._id = id;
        this.title = title;
        this.subtitle = subtitle;
        this.thumbnail = thumbnail;
        this.imgs = images;
    }

    toggleActive() {
        this.active = !this.active;
    }
}
