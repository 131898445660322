import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

import { JsonFileService } from './services/datafile/jsondata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    ver = '0.0.1';
    year = new Date().getFullYear();
    activeLinkIndex = -1;

    public displayName: string = sessionStorage.getItem('displayName');
    public authServ: AuthService;

    constructor(private _router: Router, private _authService: AuthService, private _jsonService: JsonFileService) {
        if (navigator.language === 'cn-ZH') {
          window.location.href = '/cn';
        }
    }

    ngOnInit() {
        this.authServ = this._authService;
        this._jsonService.getApiUrl();
    }

    gotoLogout() {
        this.authServ.loginStatus = false;
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('memberId');
        sessionStorage.removeItem('displayName');
        sessionStorage.removeItem('defaultMerchantId');
        sessionStorage.removeItem('defaultMerchantName');

        this._router.navigate(['/']);
    }

}
