import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { prodlist } from '../services/prods.service';
import { Prod } from '../models/prod';

@Component({
  selector: 'app-prodlist',
  templateUrl: './prodlist.component.html',
  styleUrls: ['./prodlist.component.css'],
})
export class ProdlistComponent implements OnInit {
  public prodlist: Prod[] = prodlist; // = require('../services/prods.service');
  public selectedProd: Prod;
  public errorMessage: string;

  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit() {
    this.load();
  }

  load() {
  }

  processError(error: any) {
    this.errorMessage = 'Error happened on reading product list.';
  }

  onSelect(prod: Prod) {
    this.selectedProd = prod;
    this._router.navigate(['prod', prod._id]);
  }

}
