import {Comment} from '../../blog/models/comment';

export class News {
    _id: string;
    parentId?: string;
    catId?: number;

    title: string;
    subtitle?: string;
    description: string;
    link: string;
    url?: string;
    created: string;
    enclosures?: [{url: String, length: Number, type: String }];

    content?: string;
    authorId?: string;
    authorName?: string;
    authorLogo?: string;
    lanCode?: string; // none for english;
    keywords?: string[];
    likes?: string[];
    dislikes?: string[];
    comments?: Comment[];
    score?: number;
    tags?: string[];
    active?: boolean;
    status?: string; // E-edit; P-public; p-private; V-verified;
    seq?: number;
    reads?: number;
    lastUpdated?: Date;
    dateCreated?: Date;

    constructor(id: string, parentId: string, catId: number, title: string, subtitle: string, content: string, authorId: string,
        lanCode: string, keywords: string[], tags: string[], status: string) {
        this._id = id;
        this.parentId = parentId;
        this.catId = catId;
        this.title = title;
        this.subtitle = subtitle;
        this.content = content;
        this.authorId = authorId;
        this.lanCode = lanCode;
        this.keywords = keywords;
        this.tags = tags;
        this.status = status;
        this.comments = [];
    }
}
