import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';

import { News } from '../../models/news/news';
import { NewsCategory } from '../../models/news/news-category';

import { NewsService } from '../../services/news/news.service';
import { NewscatService } from '../../services/news/newscat.service';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.css'],
})
export class NewsEditComponent implements OnInit {
  public news: News = new News('', '', 0, '', '', '', '', 'EN', null, null, '');
  public cats: NewsCategory[];
  public errorMessage: string;
  public ckeditorContent: string;

  public options: Object = {
      placeholderText: 'Edit Your Content Here!',
      charCounterCount: false
    }

  constructor(private _router: Router, private _route: ActivatedRoute, private newsService: NewsService,
    private newscatService: NewscatService) {
  }

  ngOnInit() {
    this.loadCats();

    this._route.params.forEach((params: Params) => {
        const id: string = params['id'];
        if (id) {
            this.loadNews(id);
        }
    });

  }

  loadCats() {
    this.newscatService.getAll().subscribe(
                cats => this.cats = cats,
                // cats => this.processCats(cats),
                error => this.processError(error)
            );
  }

  loadNews(id: string) {
    this.newsService.get(id).subscribe(
                ret => this.news = ret,
                error => this.processError(error)
            );
  }

  onSave() {
    this.news.authorId = sessionStorage.getItem('memberId');
    // alert(JSON.stringify(this.blog));
    if (!this.news.created) {
        this.news.created = '' + new Date().getTime();
        this.news.description = this.news.content;
        this.news.content = '';
        // alert(JSON.stringify(this.news));
        this.newsService.create(this.news).subscribe(
                ret => this.processCreate(ret),
                error => this.processError(error)
            );
    } else {
        this.newsService.update(this.news).subscribe(
                ret => this.processCreate(ret),
                error => this.processError(error)
            );
    }

  }

  onChange() {}

  onReady() {}

  onFocus() {}

  onBlur() {}

  processError(error: any) {
      this.errorMessage = 'Failed loading news.';
  }

  processCreate(createRet: any) {
      this.errorMessage = '';
      // alert(JSON.stringify(this.createRet));
  }
}
