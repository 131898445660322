import {Injectable} from '@angular/core';
import {Http, Request, Response, Headers, RequestMethod, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs/Rx';

import {Comment} from '../../blog/models/comment';
import {News} from '../../models/news/news';

@Injectable()
export class RssService {
    constructor (private http: Http) {}

    // Get rss newses
    public get(feedLink: string) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        // headers.append('x-access-token', sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Get,
            url: sessionStorage.getItem('_ApiUrl') + '/rssjson',
            headers: headers,
            body: 'feedlink=' + feedLink
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                if (res) {
                    // alert(JSON.stringify(res.json()))
                    return <News>res.json().items;
                    // return this.convertResponseToNews(res);
                }
        })
        .catch(this.logAndPassOn);
    }

    public fetchRemotePage(url: string){
        const headers = new Headers();
        headers.append('x-forwarded-host', 'foo');
        this.http.get(url, {headers: headers}).subscribe(response => {
            return response.json();
        });

    }

    private convertResponseToNews(res: Response) {
        if (res && res.status === 200) {
            const retJson = res.json();
            if (retJson) {
                const news = new News(retJson['_id'], retJson['parentId'], retJson['catId'], retJson['title'],
                                retJson['subtitle'], retJson['content'], retJson['authorId'], retJson['lanCode'],
                                retJson['keywords'], retJson['tags'], retJson['status']);
                news.active = (retJson['active'] === 1) ? true : false;

                if (retJson['comments']) {
                    news.comments = new Array<Comment>();
                    retJson['comments'].forEach(item => {
                        news.comments.push(item);
                    });
                }
                return news;
            }
        }
        return null;
    }

    private logAndPassOn (error: Error) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console

        console.error(error);
        return Observable.throw(error);
    }
}
