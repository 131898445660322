import { Prod } from '../models/prod';

const prod1: Prod = new Prod(1, 'Multi-functional Universal Blockchain Platform',
                            'The gateway for privates or organizations tap into blockchain technology', '', null);
prod1.seq = 1;
prod1.mainImg = 'https://www.insurex.co/wp-content/uploads/2017/05/product.png';
prod1.summary = '<p>Blockchain can keep records immutable, however, there are many more requirements other than transactions immutable,'
    + 'identification, proof of existence, credit and trustyness relations, format of organizations as well as business logics that '
    + 'to utilize blockchain features.</p>'
    + '<p>Blockchain Gate platform </p>';

const prod2: Prod = new Prod(2, 'product title tow', 'this is about two', '', null);
prod2.seq = 2;
prod2.mainImg = '';

export const prodlist: Prod[] = [prod1, prod2];
