import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/auth-guard.service';

import { HomeComponent } from './components/home/home.component';
import { AboutusComponent } from './components/home/aboutus.component';
import { AboutuscnComponent } from './components/home/aboutus-cn.component';
import { ContactusComponent } from './components/home/contactus.component';
import { NewsComponent } from './components/news/news.component';
import { NewsEditComponent } from './components/news/news-edit.component';

import { ProdlistComponent } from './product/components/prodlist.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'aboutus-cn', component: AboutuscnComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news-edit/:id', component: NewsEditComponent },

  { path: 'prodlist', component: ProdlistComponent },

  { path: 'member', loadChildren: 'app/member/member.module#MemberModule'},
  { path: 'blogs', loadChildren: 'app/blog/blog.module#BlogModule'},
  { path: '_admin_', loadChildren: 'app/admin/admin.module#AdminModule'},
  { path: '',  component: HomeComponent },
];
@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
