import {Injectable} from '@angular/core';
import {Http, Request, Response, Headers, RequestMethod, RequestOptions} from '@angular/http';
import {JsonFileService} from '../datafile/jsondata.service';
import {Observable} from 'rxjs/Rx';

import {Contactus} from '../../models/customer/contactus';

@Injectable()
export class ContactusService {
    constructor (private http: Http, private _jsonService: JsonFileService) {}

    // Create contactus
    createContactus(contactus: Contactus) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        // headers.append("x-access-token", sessionStorage.getItem('id_token'));
        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Post,
            url: this._jsonService.apiUrl + '/contactus/create',
            headers: headers,
            body: JSON.stringify(contactus)
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                    const retJson = res.json();
                    return this.HandleSingleContactus(retJson);
            })
            .catch(this.logAndPassOn);
    }

    // Retrieve a contactus by its id.
    getContactus(id: number | string) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        // headers.append("x-access-token", sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Get,
            url: this._jsonService.apiUrl + '/contactus/' + id,
            headers: headers
            // body: JSON.stringify(theBody)
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                    const retJson = res.json();
                    return this.HandleSingleContactus(retJson);
            })
            .catch(this.logAndPassOn);
    }

    // Update contactus
    updateOrder(contactus: Contactus) {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        // headers.append("x-access-token", sessionStorage.getItem('id_token'));

        const requestoptions: RequestOptions = new RequestOptions({
            method: RequestMethod.Post,
            url: this._jsonService.apiUrl + '/contactus/update',
            headers: headers,
            body: JSON.stringify(contactus)
        });

        return this.http.request(new Request(requestoptions))
            .map((res: Response) => {
                if (res) {
                    const retJson = res.json();
                    return this.HandleSingleContactus(retJson);
                }
            })
            .catch(this.logAndPassOn);
    }

    HandleSingleContactus(ret): Contactus{
        return <Contactus>ret;
    }

    private logAndPassOn (error: Error) {
        console.error(error);
        return Observable.throw(error);
    }
}
